import {
  createStyles, makeStyles, Paper, Theme,
} from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';

const styles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(4),
  },
});

const useStyles = makeStyles(styles);

export default function FilePage() {
  const params = useParams();
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <h1>hello file</h1>
      <p>{params.fileKey}</p>
    </Paper>
  );
}
