import ApiService from './Api.service';

type LoginCredentials = { email: string, password: string };
type LoginResponse = { token: string };
export default class UserService extends ApiService {
  public login = async (cred: LoginCredentials): Promise<LoginResponse> => {
    const res = await this.api('/auth/login', { method: 'POST', body: JSON.stringify(cred) });
    return res as Promise<LoginResponse>;
  };

  public getById = async (id: number) => {
    const res = await this.api(`/users/${id}`, { method: 'GET' });
    return res as Promise<User>;
  };

  public updateById = async (user: User) => {
    const res = await this.api(`/users/${user.id}`, { method: 'PUT', body: JSON.stringify(
      Object.fromEntries(Object.entries(user).filter(([key]) => key !== 'groups'))),
    });
    return res as Promise<User>;
  };
}
