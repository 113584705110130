import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';

class AudioPlayerState {
  playedFile: string = '';

  paused: boolean = false;

  playlist: S3File[] = [];
}

const audioPlayerSlice = createSlice({
  name: 'audioPlayer',
  initialState: { ...new AudioPlayerState() }, // class instance not serializable warning
  reducers: {
    setFile: (state, action: PayloadAction<AudioPlayerState['playedFile']>) => ({
      ...state, playedFile: action.payload,
    }),
    setPlaylist: (state, action: PayloadAction<AudioPlayerState['playlist']>) => ({
      ...state, playlist: action.payload,
    }),
    addPlaylistItems:
    (state, action: PayloadAction<AudioPlayerState['playlist']>) => ({
      ...state, playlist: state.playlist.concat(action.payload),
    }),
    setPaused: (state, action: PayloadAction<boolean>) => ({ ...state, paused: action.payload }),
  },
});

export const selectAudioPlayer = (state: any) : AudioPlayerState => state.audioPlayer;
export const selectPlayedFile = createSelector(
  selectAudioPlayer,
  (player) => player.playedFile,
);
export const selectPaused = createSelector(
  selectAudioPlayer,
  (player) => player.paused,
);
export const selectPlaylist = createSelector(
  selectAudioPlayer,
  (player) => player.playlist,
);

export const audioPlayerActions = {
  ...audioPlayerSlice.actions,
};

export default audioPlayerSlice;
