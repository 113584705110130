import React, { useState, useEffect, useCallback } from 'react';
import { useDebounce } from 'use-debounce';
import { InputAdornment, Input } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { filesSearchActions } from '../../redux/FilesSearchSlice';
import useStyles from './FilesSearch.styles';

export default function FilesSearch() {
  const [searchString, setSearchString] = useState<string>('');
  const [debouncedSearch] = useDebounce(searchString, 300);
  const dispatch = useDispatch();

  const handleChange = useCallback(({ target: { value } }) => setSearchString(value), []);

  useEffect(() => {
    dispatch(filesSearchActions.setSearch(debouncedSearch));
  }, [dispatch, debouncedSearch]);

  const classes = useStyles();
  return (
    <Input
      value={searchString}
      onChange={handleChange}
      disableUnderline
      classes={{ root: classes.root }}
      className={classes.input}
      endAdornment={(
        <InputAdornment position="end">
          <Search />
        </InputAdornment>
      )}
    />
  );
}
