import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store, { history } from './redux/store';
import { BrowserRouter } from './redux/customReduxRouter';
import FullScreenSpinner from './components/FullScreenSpinner';
import NotificationContainer from './components/NotificationContainer';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <ConnectedRouter history={history}> */}
      <BrowserRouter history={history}>
        <FullScreenSpinner />
        <NotificationContainer />
        <App />
      </BrowserRouter>
      {/* </ConnectedRouter> */}
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
