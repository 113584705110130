import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../store';

export const selectSpinnerState = (state: AppState) => state.spinner;

export const selectLoading = createSelector(
  selectSpinnerState,
  (state) => state.isLoading,
);
export const selectErrors = createSelector(
  selectSpinnerState,
  (state) => state.errors,
);
