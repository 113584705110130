import { createTheme } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import ThemeService from '../services/Theme.service';

export default function useThemeSwitcher() {
  const [darkTheme, setDarkTheme] = useState(ThemeService.preferredTheme);
  const theme = useMemo(() => createTheme({
    palette: {
      type: darkTheme ? 'dark' : 'light',
    },
  }), [darkTheme]);

  useEffect(() => {
    ThemeService.darkTheme = darkTheme;
  }, [darkTheme]);

  return [theme, darkTheme, setDarkTheme] as const;
}
