import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 400,
    padding: theme.spacing(3),
    opacity: 0.8,
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  root: {
    marginTop: theme.spacing(20),
  },
  header: {
    textAlign: 'center',
    maxWidth: 460,
  },
}), { name: 'LoginLayout' });

export default useStyles;
