import { Modal } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import LoopIcon from '@material-ui/icons/Loop';
import { selectLoading } from '../../redux/spinner/spinner.selector';
import useStyles from './FullScreenSpinner.styles';

export default function FullScreenSpinner() {
  const isLoading = useSelector(selectLoading);
  const classes = useStyles();
  return (
    <Modal
      open={isLoading}
      onClose={() => undefined}
      className={classes.modal}
     >
      <LoopIcon className={classes.icon} />
    </Modal>
  );
}