import {
  Container, Box, TextField, Paper, Button, Typography,
} from '@material-ui/core';

import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useStyles from './LoginLayout.styles';
import { userLoginRequest } from '../redux/user/user.slice';
import useBackgroundImage from '../hooks/useBackgroundImage';
import bgImage from '../assets/bg-Kristine-Rose-Photography-20200616_001s1024.jpg';

class LoginCredentials {
  email: string = '';

  password: string = '';
}

export default function LoginLayout() {
  const [credentials, setCredentials] = useState<LoginCredentials>(new LoginCredentials());

  const dispatch = useDispatch();

  const handleChange = useCallback(({ target }) => {
    setCredentials((cred: LoginCredentials) => ({
      ...cred,
      [target.name]: target.value,
    }));
  }, []);

  const handleLogin = useCallback(() => {
    dispatch(userLoginRequest(credentials));
  }, [credentials, dispatch]);

  const handleKeyPress = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== 'Enter') {
      return;
    }
    handleLogin();
  }, [handleLogin]);

  useBackgroundImage(bgImage);
  const classes = useStyles();

  return (
    <Container>
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" className={classes.root}>
        <Typography variant="h4" gutterBottom className={classes.header}>
          Hello! Please enter your email and password.
        </Typography>
        <Paper className={classes.paper} onKeyPress={handleKeyPress}>
          <TextField
            label="Email"
            name="email"
            type="email"
            value={credentials.email}
            onChange={handleChange}
            className={classes.input}
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            value={credentials.password}
            onChange={handleChange}
            className={classes.input}
          />
          <Button variant="contained" onClick={handleLogin} color="primary">Login</Button>
        </Paper>
      </Box>
    </Container>
  );
}
