import { Box, IconButton, Modal } from '@material-ui/core';
import React from 'react';
import ReactPlayer from 'react-player';
import { getHrefByKey } from '../../utils/helpers';
import { Close } from '@material-ui/icons';
import { addErrorNotification } from '../../redux/spinner/spinner.slice';
import { useDispatch } from 'react-redux';

type VideoPlayerProps = {
  url: string | null;
  onClose: () => void;
};

export default function VideoPlayer({ url, onClose }: VideoPlayerProps) {
  const dispatch = useDispatch();
  return (
    <Modal
      open={!!url}
      onClose={onClose}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box>
        <IconButton onClick={onClose} style={{ position: 'fixed', top: '30px', right: '30px' }}>
          <Close />
        </IconButton>
        <ReactPlayer
          url={getHrefByKey(url ?? '')}
          controls
          onError={() => dispatch(addErrorNotification({
            type: 'videoPlayerError', payload: 'Video failed to play',
          }))}
        />
      </Box>
    </Modal>
  );
}