import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../store';

export const selectFolderPermissionState = (state: AppState) => state.folderPermissions;
export const selectFolderPermissionList = createSelector(
  selectFolderPermissionState,
  (state) => state.list,
);
export const selectCurrentFolderPermission = createSelector(
  selectFolderPermissionState,
  (state) => state.current,
);

export const selectPublicPaths = createSelector(
  selectFolderPermissionList,
  (state) => state.filter((f) => f.type === 'public')
    .map((f) => f.path),
);

