/* eslint-disable  */
import React, { useEffect, ReactNode, useMemo, useCallback } from 'react';

import {
  AppBar, Breadcrumbs,
} from '@material-ui/core';
import { Home } from '@material-ui/icons'
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import useStyles from './MainLayout.styles';
import AuthService from '../services/Auth.service';
import { getUserRequest } from '../redux/user/user.slice';
import MainToolbar from '../components/MainToolbar/MainToolbar';
import { useSearchParams } from 'react-router-dom';


type MainLayoutProps = {
  children: ReactNode,
  darkTheme: boolean,
  onSwitchTheme: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void | undefined,
};

export default function MainLayout({ children, onSwitchTheme, darkTheme }: MainLayoutProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const pathMemo = useMemo(() => (Object.fromEntries(searchParams.entries())?.path as string) || '/', [searchParams]);

  const handleChangePath = useCallback((path?: string) => () => {
    const newPath = path || pathMemo.split('/').slice(0, -2).join('/').concat('/');
    setSearchParams({ path: newPath });
  }, [pathMemo, setSearchParams]);

  const isPathElementActive = useCallback((el: string) => {
    return pathMemo.split('/').slice(-2)[0] === el;
  }, [pathMemo]);

  useEffect(() => {
    const id = AuthService.getUserId();
    if (id) {
      dispatch(getUserRequest(id));
    }
  }, [dispatch]);
  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar)}
      >
        <MainToolbar darkTheme={darkTheme} onSwitchTheme={onSwitchTheme} />
      </AppBar>
      <main
        className={clsx(classes.content)}
      >
        <div className={classes.drawerHeader} />
        <div className={classes.breadCrumbsContainer}>
          <Breadcrumbs>

            <Home
              className={clsx(classes.link, pathMemo === '/' && classes.activeLink)}
              onClick={handleChangePath('/')}
            />

            {pathMemo.split('/').slice(0, -1).map((el, index, arr) => (
              <div
                className={clsx(classes.link, isPathElementActive(el) && classes.activeLink)}
                key={el}
                onClick={handleChangePath(arr.slice(0, index + 1).join('/').concat('/'))}
              >
                {el}
              </div>
            ))}
          </Breadcrumbs>
        </div>
        {children}
      </main>
    </div>
  );
}
