import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  root: {},
  divider: {
    margin: '15px 5px',
  },
  playerContainer: {
    minHeight: 50,
    minWidth: 300,
    padding: 5,
    backgroundColor: palette.type === 'dark' ? palette.background.default : '#548ea9',
  },
  audio: {
    width: '100%',
    '&:focus': {
      outline: 'none',
    },
    // backgroundColor: palette.background.paper,
  },
  displayNone: {
    display: 'none',
  },
}), { name: 'AudioPlayer' });

export default useStyles;
