import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';

export type LoginCredentials = { email: string, password: string };

export class UserState implements User {
  id = 0;
  email = '';
  firstName = '';
  lastName = '';
  groups: any = [];
  created_at = '';
  updated_at = '';
}
const PREFIX = 'user';
const { actions, reducer } = createSlice({
  name: PREFIX,
  initialState: new UserState(),
  reducers: {
    getSuccess: (state, action: PayloadAction<UserState>) => action.payload,
    updateSuccess: (state, action: PayloadAction<UserState>) => action.payload,
    logoutSuccess: (state, action: PayloadAction<UserState>) => action.payload,
    loginSuccess: (state, action: PayloadAction<UserState>) => action.payload,
  },
});

export const userLoginRequest = createAction<LoginCredentials>(`${PREFIX}/loginRequest`);
export const userLoginError = createAction<string>(`${PREFIX}/loginError`);
export const userLogoutRequest = createAction(`${PREFIX}/logoutRequest`);
export const userLogoutError = createAction<unknown>(`${PREFIX}/logoutError`);
export const getUserRequest = createAction<number>(`${PREFIX}/getRequest`);
export const getUserError = createAction<unknown>(`${PREFIX}/getError`);
export const updateUserRequest = createAction<User>(`${PREFIX}/updateRequest`);
export const updateUserError = createAction<string>(`${PREFIX}/updateError`);


const userSlice = {
  actions,
  reducer,
  initialState: new UserState(),
};

export default userSlice;
