import { Middleware } from '@reduxjs/toolkit';
import {
  addErrorNotification,
  addReqSpinner,
  removeErrorNotification,
  removeReqSpinner,
} from '../redux/spinner/spinner.slice';

const ERRORS_TIMEOUT_MS = 5000;

const spinnerMiddleware: Middleware = (store) => (next)  => (action)  => {
  if (action.type.endsWith('Request')) {
    store.dispatch(addReqSpinner(action.type));
  } else if (action.type.endsWith('Success')) {
    store.dispatch(removeReqSpinner(action.type.replace('Success', 'Request')));
  } else if (action.type.endsWith('Error')) {
    store.dispatch(removeReqSpinner(action.type.replace('Error', 'Request')));
    store.dispatch(addErrorNotification(action));
    setTimeout(() => {
      store.dispatch(removeErrorNotification(action));
    }, ERRORS_TIMEOUT_MS);
  }
  next(action);
};

export default spinnerMiddleware;

