import React, { useCallback, memo, useState, useMemo, useEffect } from 'react';
import {
  Table, TableHead, TableRow, TableCell, TableBody, IconButton,
} from '@material-ui/core';
import {
  SubdirectoryArrowLeft,
  Folder,
  Pause,
  PlayArrow,
  GetApp,
  Visibility,
  Share,
} from '@material-ui/icons';
import fileSize from 'file-size';
import { useSelector, useDispatch } from 'react-redux';
import FileIcon from '../FileIcon/FIleIcon';
import { isAudioFile, downloadFile, isImageFile, isVideoFile } from '../../utils/helpers';
import useStyles from './FilesTable.styles';
import { selectPlayedFile, selectPaused, audioPlayerActions } from '../../redux/AudioPlayerSlice';
import ImageViewer from '../ImageViewer';
import VideoPlayer from '../VideoPlayer';
import { selectIsAuth } from '../../redux/user/user.selector';
import {
  createFolderPermissionsRequest,
  deleteFolderPermissionsRequest,
  getFolderPermissionsRequest,
} from '../../redux/folderPermission/folderPermission.slice';
import { selectFolderPermissionList } from '../../redux/folderPermission/folderPermission.selector';
import clsx from 'clsx';

type FilesTableProps = {
  files: S3File[],
  onChangePath: (path?: string) => () => void,
};

function FilesTable({ files, onChangePath } : FilesTableProps) {
  const classes = useStyles();
  const playedFile = useSelector(selectPlayedFile);
  const paused = useSelector(selectPaused);
  const isAuth = useSelector(selectIsAuth);
  const publicPaths = useSelector(selectFolderPermissionList);
  const dispatch = useDispatch();
  const handleChangeAudio = useCallback((key: string) => () => {
    if (paused && playedFile && playedFile === key) {
      dispatch(audioPlayerActions.setPaused(false));
      return;
    }
    dispatch(audioPlayerActions.setFile(key));
  }, [paused, playedFile, dispatch]);

  const [isImgViewerOpen, setImgViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState<null | number>(null);
  const [currentVideo, setCurrentVideo] = useState<null | string>(null);
  const images = useMemo(() => files.filter(f => isImageFile(f)), [files]);

  const handleDownload = useCallback((file) => () => downloadFile(file.Key), []);
  const handlePause = useCallback(() => dispatch(audioPlayerActions.setPaused(true)), [dispatch]);
  const handleImageClick = useCallback((file: S3File) => () => {
    const index = images.findIndex((f) => f.Key === file.Key);
    setCurrentImage(index === -1 ? 0 : index);
    setTimeout(() => { setImgViewerOpen(true); }, 100);
  }, [images]);
  const handleVideoClick = useCallback((file: S3File) => () => {
    setCurrentVideo(file.Key);
  }, [images]);
  const handleShare = useCallback((file: S3File) => () => {
    const path = publicPaths.find(p => file.Key.startsWith(p.path));
    if (path) {
      dispatch(deleteFolderPermissionsRequest(path.id));
    } else {
      dispatch(createFolderPermissionsRequest({ path: file.Key, type: 'public' } as FolderPermission));
    }
  }, [publicPaths]);

  useEffect(() => {
    dispatch(getFolderPermissionsRequest());
  }, []);
  return (
    <>
      <ImageViewer
        isOpen={isImgViewerOpen}
        onClose={() => { setImgViewerOpen(false); setCurrentImage(null);}}
        currentImageIndex={currentImage}
        images={images}
       />
       <VideoPlayer
          url={currentVideo}
          onClose={() => setCurrentVideo(null)}
        />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" padding="checkbox" className={classes.cursor} onClick={onChangePath()}>
              <SubdirectoryArrowLeft transform="rotate(90)" />
            </TableCell>
            <TableCell>
              Name
            </TableCell>
            <TableCell align="right">
              Size
            </TableCell>
            <TableCell align="right">
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((file) => (
            <TableRow
              key={file.name}
            >
              <TableCell align="left">
                {file.type === 'directory' && <Folder />}
                {file.type === 'file' && <FileIcon fileName={file.name} />}
              </TableCell>
              <TableCell
                className={classes.cursor}
                onClick={file.type === 'directory' ? onChangePath(file.Key) : undefined}
              >
                {file.name}
              </TableCell>
              <TableCell align="right">
                {typeof file.Size === 'number' ? fileSize(file.Size).human() : '-'}
              </TableCell>
              <TableCell padding="none" align="right">
                  {isAudioFile(file) && ((playedFile === file.Key && !paused) ? (
                    <IconButton onClick={handlePause}>
                      <Pause />
                      {' '}
                    </IconButton>
                  ) : (
                    <IconButton onClick={handleChangeAudio(file.Key)}>
                      <PlayArrow />
                      {' '}
                    </IconButton>
                  ))}
                  {isImageFile(file) && (
                    <IconButton onClick={handleImageClick(file)}>
                      <Visibility />
                    </IconButton>
                  )}
                  {isVideoFile(file) && (
                    <IconButton onClick={handleVideoClick(file)}>
                      <Visibility />
                    </IconButton>
                  )}
                  {isAuth && (
                    <IconButton onClick={handleShare(file)}>
                      <Share className={clsx(publicPaths.some(p => file.Key.startsWith(p.path)) && classes.shared)}/>
                    </IconButton>
                  )}
                  {file.type === 'file' && (
                  <IconButton onClick={handleDownload(file)}>
                    {' '}
                    <GetApp />
                  </IconButton>
                  )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

export default memo(FilesTable);
