import { useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  body: {
    backgroundImage: ({ url } : { url: string }) => `url(${url})`,
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },
}), { name: 'useBackgroundImage' });

export default function useBackgroundImage(url: string) {
  const classes = useStyles({ url });
  useLayoutEffect(() => {
    document.body.className = classes.body;
  }, [url, classes.body]);
}
