import { all, call, put, takeEvery } from 'redux-saga/effects';
import FileService from '../../services/File.service';
import { fileUploadError, fileUploadRequest, fileUploadSuccess } from './file.slice';

const fileService = new FileService();

function* fileUploadSaga({ payload }: { payload: string }) {
  try {

    const r: AsyncReturnType<typeof fileService.uploadFile> = yield call(fileService.uploadFile, payload );

    yield put(fileUploadSuccess(r as string));
  } catch (error) {
    yield put(fileUploadError((error as { message: string }).message));
  }
}

export default function* fileSaga() {
  yield all([
    takeEvery<typeof fileUploadRequest>(fileUploadRequest, fileUploadSaga),
  ]);
}