import { Box, Button, TextField } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../redux/user/user.selector';
import { updateUserRequest } from '../../redux/user/user.slice';

const PROPERTIES_TO_SHOW: Array<keyof User> = ['email', 'firstName', 'lastName', 'created_at'];
function formatProfileItems(property: typeof PROPERTIES_TO_SHOW[number], value: string) {
  if (property === 'created_at') {
    return new Date(value).toLocaleString();
  }
  return value;
}

export default function Profile() {
  const dispatch = useDispatch();
  const me = useSelector(selectUser);
  const [user, setUser] = useState(me);
  const handleChange = useCallback((property: string) => ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setUser((u) => ({ ...u, [property]: target.value }));
  }, []);
  const handleSave = useCallback(() => {
    dispatch(updateUserRequest(user));
  }, [user]);
  useEffect(() => {
    setUser(me);
  }, [me]);
  return (
    <Box padding="10px">
      {PROPERTIES_TO_SHOW.map((property) => (
        <TextField
          key={property}
          label={property.toUpperCase()}
          value={formatProfileItems(property, user[property])}
          variant="outlined"
          style={{ display: 'block', marginBottom: '16px' }}
          fullWidth
          InputLabelProps={{ shrink: true }}
          onChange={handleChange(property)}
          disabled={['created_at', 'email'].includes(property)}
        />
      ))}
      <Button variant="outlined" color="primary" onClick={handleSave}>Save</Button>
    </Box>
  );
}
