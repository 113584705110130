import { useState, useEffect, useMemo } from 'react';
import { getFilesList } from '../utils/api';

export default function useFiles(path: string): [S3File[], boolean] {
  const [files, setFiles] = useState<S3File[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const result = useMemo<[S3File[], boolean]>(() => ([files, loading]), [files, loading]);

  useEffect(() => {
    setLoading(true);
    getFilesList(path)
      .then(({ data }) => {
        setFiles(data);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [path]);

  return result;
}
