import React, { useCallback, useEffect, useState } from 'react';
import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { selectFolderPermissionList } from '../../redux/folderPermission/folderPermission.selector';
import {
  deleteFolderPermissionsRequest,
  getFolderPermissionsRequest,
} from '../../redux/folderPermission/folderPermission.slice';
import FolderPermissionModal from './components/FolderPermissionModal/FolderPermissionModal';

export default function FoldersSetup() {
  const folders = useSelector(selectFolderPermissionList);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentFid, setCurrentFid] = useState<number | null>(null);

  const handleCreate = useCallback(() => {
    setModalOpen(true);
    setCurrentFid(null);
  }, []);
  const handleEdit = useCallback((id: number) => () => {
    setModalOpen(true);
    setCurrentFid(id);
  }, []);
  const handleDelete = useCallback((id: number) => () => {
    dispatch(deleteFolderPermissionsRequest(id));
  }, []);
  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setCurrentFid(null);
  }, []);

  useEffect(() => {
    dispatch(getFolderPermissionsRequest());
  }, []);
  return (
    <Box padding="10px">
      <IconButton onClick={handleCreate} style={{ display: 'block' }}>
        <Box border="1px solid white" borderRadius="3px" padding="4px 10px">
          +
        </Box>
      </IconButton>
      <FolderPermissionModal
        open={modalOpen}
        onClose={handleCloseModal}
        id={currentFid}
      />
      <Table>
        <Table>
          <TableHead>
            <TableCell>
              Path
            </TableCell>
            <TableCell>
              Type
            </TableCell>
            <TableCell>
              Created
            </TableCell>
            <TableCell align="right">
              Actions
            </TableCell>
          </TableHead>
          <TableBody>
            {folders.map((f) => (
              <TableRow key={f.id}>
                <TableCell>
                  {f.path}
                </TableCell>
                <TableCell>
                  {f.type}
                </TableCell>
                <TableCell>
                  {new Date(f.created_at).toLocaleString()}
                </TableCell>
                <TableCell align="right">
                  <IconButton onClick={handleEdit(f.id)}><EditIcon /></IconButton>
                  <IconButton onClick={handleDelete(f.id)}><DeleteIcon /></IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Table>
    </Box>
  );
}