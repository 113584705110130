import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  cursor: {
    cursor: 'pointer',
  },
  shared: {
    border: '1px solid grey',
    borderRadius: '5px',
  },
}));

export default useStyles;
