import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
// import { connectRouter, RouterState, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import audioPlayerSlice from './AudioPlayerSlice';
import filesSearchSlice from './FilesSearchSlice';
import userSlice from './user/user.slice';
import userSaga from './user/user.saga';
import { routerReducer, routerMiddleware } from './customReduxRouter';
import fileSaga from './file/file.saga';
import { FolderPermissionReducer } from './folderPermission/folderPermission.slice';
import folderPermissionSaga from './folderPermission/folderPermission.saga';
import { SpinnerReducer } from './spinner/spinner.slice';
import spinnerMiddleware from '../middlewares/spinner';

const sagaMiddleWare = createSagaMiddleware();
export const history = createBrowserHistory({ window });

function* rootSaga() {
  yield all([
    userSaga(),
    fileSaga(),
    folderPermissionSaga(),
  ]);
}

const store = configureStore({
  reducer: {
    audioPlayer: audioPlayerSlice.reducer,
    filesSearch: filesSearchSlice.reducer,
    user: userSlice.reducer,
    router: routerReducer,
    folderPermissions: FolderPermissionReducer,
    spinner: SpinnerReducer,
  },
  middleware: [sagaMiddleWare, routerMiddleware(history), spinnerMiddleware],
});

sagaMiddleWare.run(rootSaga);

export type AppState = ReturnType<typeof store.getState>;

export default store;
