import ApiService from './Api.service';

export default class FolderPermissionService extends ApiService {
  async getList() {
    console.log('service');
    const res = await this.api('/folders', { method: 'GET' });
    return res as Promise<FolderPermission[]>;
  }

  async create(entity: FolderPermission) {
    const res = await this.api('/folders', { method: 'POST', body: JSON.stringify(entity) });
    return res as Promise<FolderPermission>;
  }

  async update(entity: Partial<FolderPermission>) {
    const res = await this.api(`/folders/${entity.id}`, { method: 'PUT', body: JSON.stringify(entity) });
    return res as Promise<FolderPermission>;
  }

  async delete(id: number) {
    await this.api(`/folders/${id}`, { method: 'DELETE' });
    return null;
  }

  async getOne(id: number) {
    const res = await this.api(`/folders/${id}`, { method: 'GET' });
    return res as Promise<FolderPermission>;
  }
}