/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { MusicNote, InsertDriveFile, Image } from '@material-ui/icons';
import { SvgIconProps } from '@material-ui/core';
import { isAudioFile, isImageFile } from '../../utils/helpers';

type Props = SvgIconProps & {
  fileName: string,
  // animating?: boolean,
};

export default function FileIcon({ fileName, ...restProps } : Props) {
  switch (true) {
    case isAudioFile({ name: fileName }):
      return <MusicNote {...restProps} />;

    case isImageFile({ name: fileName }):
      return <Image {...restProps} />;

    default:
      return <InsertDriveFile {...restProps} />;
  }
}
