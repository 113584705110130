import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import {
  Paper, Grid, Typography, IconButton, Divider, Fade, FormGroup, FormControlLabel, Checkbox,
} from '@material-ui/core';

import { SkipPrevious, SkipNext, Stop } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import AudioSpectrum from 'react-audio-spectrum2';
import csx from 'clsx';
import { getHrefByKey, getNameByKey } from '../../utils/helpers';
import useStyles from './AudioPlayer.styles';
import { ReactComponent as MusicAnim } from './animMusic.svg';
import {
  audioPlayerActions, selectPlayedFile, selectPaused, selectPlaylist,
} from '../../redux/AudioPlayerSlice';
import { addErrorNotification } from '../../redux/spinner/spinner.slice';

export default function AudioPlayer() {
  const audioRef = useRef<HTMLAudioElement>(null);
  const dispatch = useDispatch();
  const setPlayedFile = useCallback(
    (file: string) => dispatch(audioPlayerActions.setFile(file)),
    [dispatch],
  );

  const [showSpectrum, setShowSpectrum] = useState(false);
  const playedFile = useSelector(selectPlayedFile);
  const setPaused = useCallback(
    (paused: boolean) => dispatch(audioPlayerActions.setPaused(paused)),
    [dispatch],
  );
  const paused = useSelector(selectPaused);
  const audioFiles = useSelector(selectPlaylist);

  const handlePlay = (index: number) => {
    const indexOfEndedFile = audioFiles.findIndex((file) => file.Key === playedFile);
    const getKeyOfNextAudioFile = (indexOfCurrent: number) => {
      // for index of current not found or last file
      if (indexOfCurrent === -1 || !audioFiles[indexOfEndedFile + index]) {
        return audioFiles[0].Key;
      }
      return audioFiles[indexOfEndedFile + index].Key;
    };
    // Play next file on end or start from first
    setPlayedFile(getKeyOfNextAudioFile(indexOfEndedFile));
  };
  const handleFileEnd = () => {
    handlePlay(1);
  };

  useEffect(() => {
    if (playedFile && audioRef !== null && audioRef.current !== null) {
      (audioRef!.current!).load();
      if (paused) {
        setPaused(false);
      } else {
        (audioRef!.current!).play();
      }
    }
    // eslint-disable-next-line
  }, [playedFile]);

  useEffect(() => {
    if (audioRef !== null && audioRef.current !== null) {
      if (paused) {
        (audioRef!.current!).pause();
      } else {
        (audioRef!.current!).play();
      }
    }
  }, [paused]);

  const classes = useStyles();
  return (
    <Paper
      className={classes.playerContainer}
    >
      <Grid container alignItems="center" justify="space-between" style={{ marginBottom: '15px' }}>
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <MusicAnim />
            </Grid>
            <Grid item>
              <Typography variant="h6">Now Playing:</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{getNameByKey(playedFile)}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <IconButton
            color="primary"
            aria-label="Prev Trek"
            onClick={() => handlePlay(-1)}
          >
            <SkipPrevious />

          </IconButton>
          <IconButton color="primary" aria-label="Skip Trek" onClick={() => handlePlay(1)}><SkipNext /></IconButton>
          <IconButton color="secondary" aria-label="Stop Trek" onClick={() => setPlayedFile('')}><Stop /></IconButton>
        </Grid>
      </Grid>
      <FormGroup row>
        <FormControlLabel
          control={(
            <Checkbox
              checked={showSpectrum}
              onChange={() => setShowSpectrum((s) => !s)}
              name="showSpectrum"
              color="primary"
            />
          )}
          label="Show animation"
        />
      </FormGroup>
      {/* {showSpectrum
        && ( */}
      <Fade in={showSpectrum} timeout={1500}>
        <AudioSpectrum
          id="audio-canvas"
          height={40}
          width={1020}
          audioId="audio-element"
          capColor="#ff8484"
          capHeight={2}
          meterWidth={4}
          meterCount={768}
          meterColor={[
            { stop: 0, color: '#981CEB' },
            { stop: 0.5, color: 'blue' },
            { stop: 1, color: 'red' },
          ]}
          gap={1}
          className={csx(!showSpectrum && classes.displayNone)}
        />
      </Fade>
      {/* )} */}
      <Divider classes={{ root: classes.divider }} />
      <audio
        id="audio-element"
        crossOrigin="use-credentials"
        className={classes.audio}
        ref={audioRef}
        preload="metadata"
        autoPlay
        controls
        onEnded={handleFileEnd}
        onError={() => dispatch(addErrorNotification({ type: 'audioPlayerError', payload: 'Audio failed to play' }))}
      >
        <track kind="captions" />
        <source src={getHrefByKey(playedFile)} />
      </audio>
    </Paper>
  );
}
