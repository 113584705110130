import {
  Box, Button, FormControlLabel, Switch, Toolbar,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import React, { useCallback, useMemo } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FilesSearch from '../FilesSearch/FilesSearch';
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import { selectIsAuth } from '../../redux/user/user.selector';
import useStyles from './MainToolbar.styles';
import { fileUploadRequest } from '../../redux/file/file.slice';

type MainToolbarProps = {
  darkTheme: boolean,
  onSwitchTheme: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void | undefined,
  // eslint-disable-next-line react/require-default-props
  disableSearch?: boolean,
};

export default function MainToolbar({ onSwitchTheme, darkTheme, disableSearch = false }: MainToolbarProps) {
  const isAuth = useSelector(selectIsAuth);
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const pathMemo = useMemo(() => (Object.fromEntries(searchParams.entries())?.path as string) || '/', [searchParams]);
  const handleUploadClick = useCallback(() => {
    dispatch(fileUploadRequest(pathMemo));
  }, [pathMemo]);
  return (
    <Toolbar className={classes.toolbarRoot}>
      <Box display="inline-flex" alignItems="center">
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr">
          <NavLink to="/files" className={classes.navLink}>
            Files
          </NavLink>
          {isAuth && (
            <NavLink to="/profile" className={classes.navLink}>
              Profile
            </NavLink>
          )}
          {isAuth && (
            <NavLink to="/folders-setup" className={classes.navLink}>
              Folders
            </NavLink>
          )}
        </Box>
      </Box>
      {!disableSearch && <FilesSearch /> }
      <Box display="inline-flex" alignItems="center">
        { isAuth && (
          <Button 
            variant="outlined" 
            color="secondary" 
            style={{ marginRight: '8px' }}
            onClick={handleUploadClick}
            startIcon={<CloudUploadIcon />}
            >Upload</Button>
        ) }
        <FormControlLabel
          control={<Switch checked={darkTheme} onChange={onSwitchTheme} name="darkTheme" />}
          label={darkTheme ? 'Light' : 'Dark'}
        />
        <ProfileMenu />
      </Box>
    </Toolbar>
  );
}
