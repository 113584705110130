import React, { useEffect, ReactNode } from 'react';

import {
  AppBar,
} from '@material-ui/core';

import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import useStyles from './MainLayout.styles';
import AuthService from '../services/Auth.service';
import { getUserRequest } from '../redux/user/user.slice';
import MainToolbar from '../components/MainToolbar/MainToolbar';

type SettingsLayoutProps = {
  children: ReactNode,
  darkTheme: boolean,
  onSwitchTheme: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void | undefined,
};

export default function SettingsLayout({ children, onSwitchTheme, darkTheme }: SettingsLayoutProps) {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const id = AuthService.getUserId();
    if (id) {
      dispatch(getUserRequest(id));
    }
  }, [dispatch]);
  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar)}
      >
        <MainToolbar darkTheme={darkTheme} onSwitchTheme={onSwitchTheme} disableSearch />
      </AppBar>
      <main
        className={clsx(classes.content)}
      >
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  );
}
