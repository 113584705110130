import qs from 'qs';
import ApiService from './Api.service';

export default class FileService extends ApiService {
  uploadFile = (path: string) => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    return new Promise((resolve) => {
      fileInput.onchange = async (event) => {
        const file = (event.target as any).files[0] as File;
        const formData = new FormData();
        formData.append('file', file);
        const result = await this.api(`/files/upload?${qs.stringify({ path })}`, {
          method: 'POST', body: formData,
        }, true);
        resolve(result);
      };
      fileInput.click();
    });
  };
}