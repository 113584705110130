import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  root: {
  },
  menuList: {
    minWidth: 200,
  },
  expandIcon: {
    transition: 'transform 0.5s ease-out',
  },
  rotate180: {
    transform: 'rotate(180deg)',
  },
  danger: {
    color: palette.error.main,
  },
}), { name: 'ProfileMenu' });

export default useStyles;
