import jwt_decode from 'jwt-decode';

type ParsedToken = string | { [key: string]: any; } | null;

export default class AuthService {
  static getToken = () => window.localStorage.getItem('token') || '';
  static setToken = (token: string) => window.localStorage.setItem('token', token);
  static removeToken = () => window.localStorage.removeItem('token');
  static getParsedToken = (): ParsedToken => { 
    const token = AuthService.getToken();
    if (!token) {
      return null;
    }
    
    try {
      return jwt_decode(token);
    } catch (error) {
      console.warn(error);
      return null;
    }
  };

  static getUserId = (): number | null => {
    const parsedToken = AuthService.getParsedToken();
    if (typeof parsedToken === 'string' || !parsedToken) {
      return null;
    }
    return parsedToken.id as number;
  };
}
