export default class ThemeService {
  static lsKey = 'darkTheme';
  static get preferredTheme(): boolean {
    const value = localStorage.getItem(ThemeService.lsKey);

    if (!value) {
      return true;
    }
    return value === 'true';
  }

  static set darkTheme(value: boolean) {
    localStorage.setItem(ThemeService.lsKey, value ? 'true' : 'false');
  }
}
