import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';

class FilesSearchState {
  searchString: string = '';
}

const filesSearchSlice = createSlice({
  name: 'filesSearch',
  initialState: new FilesSearchState(),
  reducers: {
    setSearch: (state, action: PayloadAction<FilesSearchState['searchString']>) => ({
      ...state, searchString: action.payload,
    }),
  },
});

export const selectFilesSearch = (state: any): FilesSearchState => state.filesSearch;

export const selectSearchString = createSelector(
  selectFilesSearch,
  ({ searchString }) => searchString,
);

export const filesSearchActions = {
  ...filesSearchSlice.actions,
};

export default filesSearchSlice;
