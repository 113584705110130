import React, { useReducer } from 'react';
import { BrowserHistory, Update, History } from 'history';
import { Router } from 'react-router-dom';
import {
  Middleware, createSlice,
} from '@reduxjs/toolkit';

type ReduxAction<T = any> = {
  type: string,
  payload?: T,
};

const { reducer: routerReducer, actions: routerActions } = createSlice({
  name: 'router',
  initialState: {
    path: window.location.pathname,
  },
  reducers: {
    push: (state, action) => ({ path: action.payload }),
  },
});

export const routerMiddleware = (history: BrowserHistory): Middleware => () => (
  next,
) => (action: ReduxAction) => {
  switch (action.type) {
    case routerActions.push.type: {
      history.push(action.payload);
      return next(action);
    }
    default:
      return next(action);
  }
};

export interface BrowserRouterProps {
  children?: React.ReactNode;
  window?: Window;
  history: History;
}

export function BrowserRouter({ children, history }: BrowserRouterProps) {
  // const historyRef = React.useRef<BrowserHistory>();
  // if (historyRef.current == null) {
  //   historyRef.current = createBrowserHistory({ window });
  // }

  // const history = historyRef.current;
  const [state, dispatch] = useReducer((_: Update, action: Update) => action, {
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => history.listen(dispatch), [history]);

  return (
    <Router
    // @ts-ignore
      action={state.action}
      location={state.location}
      navigator={history}
    >
      {children}
    </Router>
  );
}

export { routerActions, routerReducer };
