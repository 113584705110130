import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentFolderPermission } from '../../../../redux/folderPermission/folderPermission.selector';
import {
  cleanCurrentFolderPermission,
  createFolderPermissionsRequest,
  getOneFolderPermissionsRequest,
  updateFolderPermissionsRequest,
} from '../../../../redux/folderPermission/folderPermission.slice';

type FolderPermissionModalProps = {
  open: boolean;
  id: number | null;
  onClose: () => void;
};

export default function FolderPermissionModal({ open, id, onClose }: FolderPermissionModalProps) {
  const isEditModal = !!id;
  const folderRedux = useSelector(selectCurrentFolderPermission);
  const dispatch = useDispatch();
  const [folder, setFolder] = useState<Partial<FolderPermission>>();

  const handleClose = useCallback(() => {
    dispatch(cleanCurrentFolderPermission());
    onClose();
  }, [onClose]);

  const handleChange = useCallback((e: React.ChangeEvent<{ name?: string, value: string | unknown }>) => {
    setFolder((prevF) => ({
      ...prevF,
      [e.target.name as 'path' | 'type']: e.target.value,
    }));
  }, []);
  const handleSubmit = useCallback(() => {
    const f = folder as FolderPermission;
    dispatch(isEditModal ? updateFolderPermissionsRequest(f) : createFolderPermissionsRequest(f));
    handleClose();
  }, [isEditModal, folder]);

  useEffect(() => {
    if (id !== null) {
      dispatch(getOneFolderPermissionsRequest(id));
    }
  }, [id]);

  useEffect(() => {
    setFolder(folderRedux || { path: '', type: 'public' });
  }, [folderRedux]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle>{`${isEditModal ? 'Edit' : 'Create'} folder permission`}</DialogTitle>
      <DialogContent>
        <TextField style={{ marginBottom: '32px' }} 
        fullWidth name="path" label="Full Path" value={folder?.path} onChange={handleChange}/>
        <Select style={{ marginBottom: '32px' }}
        fullWidth name="type" label="Privacy type" value={folder?.type ?? 'public'} onChange={handleChange}>
          <MenuItem value="public">Public</MenuItem>
          <MenuItem value="private">Private</MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        <Button color="primary"
          variant="contained" type="submit" onClick={handleSubmit}>{`${isEditModal ? 'Save' : 'Create'}`}</Button>
        <Button color="default" onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}