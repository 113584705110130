import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ImgsViewer from 'react-images-viewer';
import { getHrefByKey } from '../../utils/helpers';
import { addErrorNotification } from '../../redux/spinner/spinner.slice';
import { useDispatch } from 'react-redux';

type ImageViewerProps = {
  isOpen: boolean,
  currentImageIndex: number | null,
  onClose: () => void,
  images: S3File[],
};

export default function ImageViewer({ isOpen, currentImageIndex, onClose, images }: ImageViewerProps) {
  const dispatch = useDispatch();
  const preparedImages = useMemo(() => {
    return images.map((f) => ({ src: getHrefByKey(f.Key) }));
  }, [images]);
  const [innerIndex, setInnerIndex] = useState(currentImageIndex);
  const handleNav = useCallback((num: number) => () => {
    setInnerIndex((i) => (i ?? 0) + num);
  }, []);
  useEffect(() => {
    setInnerIndex(currentImageIndex);
  }, [currentImageIndex]);


  if (images.length === 0 || innerIndex === null) {
    return null;
  }

  return (
    <ImgsViewer
      isOpen={isOpen}
      currImg={innerIndex}
      imgs={preparedImages}
      onClose={onClose}
      onClickPrev={handleNav(-1)}
      onClickNext={handleNav(1)}
      onError={(e: unknown) => dispatch(addErrorNotification({ type: 'imageViewerError', payload: e }))}
    />
  );
}