/* eslint-disable import/prefer-default-export */

import ApiService from '../services/Api.service';

type BaseResponse<T = any> = {
  data: T[],
  limit?: number,
  offset?: number,
  count?: number,
};

const apiService = new ApiService();
export const getFilesList = async (path: string): Promise<BaseResponse<S3File>> => {
  const result = await apiService.api(
    '/files?' + new URLSearchParams({ path }),
    { method: 'GET',
    });
  const { data } = result;
  return {
    data,
    count: data.length,
  };
};
