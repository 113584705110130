import React, { useCallback } from 'react';
import {
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import MainLayout from './layouts/MainLayout';
import LoginLayout from './layouts/LoginLayout';
import SettingsLayout from './layouts/SettingsLayout';
import Files from './pages/Files';
import Profile from './pages/Profile';
import useThemeSwitcher from './hooks/useThemeSwitcher';
import File from './pages/File';
import FoldersSetup from './pages/FoldersSetup/FoldersSetup';

const App: React.FC = () => {
  const [theme, darkTheme, setDarkTheme] = useThemeSwitcher();

  const handleSwitchTheme = useCallback((e) => {
    setDarkTheme(e.target.checked);
  }, [setDarkTheme]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Navigate to="/files?path=/" />} />
        <Route
          path="/files"
          element={
            <MainLayout darkTheme={darkTheme} onSwitchTheme={handleSwitchTheme}><Files /></MainLayout>
      }
        />
        <Route
          path="/file/:fileKey"
          element={<MainLayout darkTheme={darkTheme} onSwitchTheme={handleSwitchTheme}><File /></MainLayout>}
        />
        <Route
          path="/profile"
          element={<SettingsLayout darkTheme={darkTheme} onSwitchTheme={handleSwitchTheme}><Profile /></SettingsLayout>}
        />
        <Route
          path="/folders-setup"
          element={
          <SettingsLayout darkTheme={darkTheme} onSwitchTheme={handleSwitchTheme}>
            <FoldersSetup />
          </SettingsLayout>}
        />
        <Route path="/login" element={<LoginLayout />} />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
