import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  '@keyframes rotation': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(359deg)',
    },
  },
  icon: {
    '&:focus': {
      outline: 'none',
    },
    width: '50vw',
    height: '50vh',
    animation: '$rotation 2s infinite',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;