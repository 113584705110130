import {
  all, takeEvery, call, put,
} from 'redux-saga/effects';
// import { push } from 'connected-react-router';

import UserService from '../../services/User.service';
import AuthService from '../../services/Auth.service';
import userSlice, {
  userLoginRequest,
  getUserRequest,
  getUserError,
  LoginCredentials,
  userLoginError,
  userLogoutRequest,
  UserState,
  updateUserRequest,
  updateUserError,
  userLogoutError,
} from './user.slice';
import { routerActions } from '../customReduxRouter';

const userService = new UserService();

function* getUserSaga({ payload }: ReturnType<typeof getUserRequest>) {
  try {
    const r: AsyncReturnType<typeof userService.getById> = yield call(userService.getById, payload);
    yield put(userSlice.actions.getSuccess(r));
  } catch (error) {
    yield put(getUserError((error as { message: string }).message));
  }
}
function* updateUserSaga({ payload }: ReturnType<typeof updateUserRequest>) {
  try {
    const r: AsyncReturnType<typeof userService.updateById> = yield call(userService.updateById, payload);
    yield put(userSlice.actions.updateSuccess(r));
  } catch (error) {
    yield put(updateUserError((error as { message: string }).message));
  }
}

function* userLogoutSaga() {
  try {
    AuthService.removeToken();
    yield put(userSlice.actions.logoutSuccess(new UserState()));
  } catch (error) {
    yield put(userLogoutError(error));
  }
}

function* userLoginSaga({ payload }: { payload: LoginCredentials }) {
  try {
    const res: AsyncReturnType<typeof userService.login> = yield call(userService.login, payload);
    AuthService.setToken(res.token);
    const id = AuthService.getUserId()!;
    const r: AsyncReturnType<typeof userService.getById> = yield call(userService.getById, id);
    yield put(userSlice.actions.loginSuccess(r));
    yield put(routerActions.push('/'));
  } catch (error) {
    yield put(userLoginError((error as { message: string }).message));
  }
}

export default function* userSaga() {
  yield all([
    takeEvery<typeof userLoginRequest>(userLoginRequest, userLoginSaga),
    takeEvery<typeof getUserRequest>(getUserRequest, getUserSaga),
    takeEvery<typeof userLogoutRequest>(userLogoutRequest, userLogoutSaga),
    takeEvery<typeof updateUserRequest>(updateUserRequest, updateUserSaga),
  ]);
}
