import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectErrors } from '../../redux/spinner/spinner.selector';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { removeErrorNotification } from '../../redux/spinner/spinner.slice';

export default function NotificationContainer() {
  const errors = useSelector(selectErrors);
  const dispatch = useDispatch();

  return <>
    {errors.map((e, i) => (
      <Snackbar
        open
        key={e.type}
        style={{ bottom: `${i * 60 + 24}px` }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Alert elevation={6} variant="filled" severity="error" onClose={() => dispatch(removeErrorNotification(e))}>
          {typeof e.payload === 'string' ? e.payload : String(e.payload)}
        </Alert>
      </Snackbar>
    ))}
  </>;
}