import React, {
  useMemo, useEffect, useCallback,
} from 'react';
import {
  TableContainer, Paper,
  LinearProgress,
  Collapse, Fade,
} from '@material-ui/core';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useFiles from '../../hooks/useFiles';
import FilesTable from '../../components/FilesTable/FilesTable';
import AudioPlayer from '../../components/AudioPlayer/AudioPlayer';
import { selectPlayedFile, audioPlayerActions } from '../../redux/AudioPlayerSlice';
import { isAudioFile } from '../../utils/helpers';
import { selectSearchString } from '../../redux/FilesSearchSlice';

export default function Files() {
  const [searchParams, setSearchParams] = useSearchParams();
  const playedFile = useSelector(selectPlayedFile);
  const dispatch = useDispatch();
  const pathMemo = useMemo(() => (Object.fromEntries(searchParams.entries())?.path as string) || '/', [searchParams]);

  const handleChangePath = useCallback((path?: string) => () => {
    const newPath = path || pathMemo.split('/').slice(0, -2).join('/').concat('/');
    setSearchParams({ path: newPath });
  }, [pathMemo, setSearchParams]);
  const [files, loading] = useFiles(pathMemo);
  const searchString = useSelector(selectSearchString);
  const filteredFiles = useMemo(
    () => files.filter(
      (file) => searchString.length < 3
      || file.name.toLowerCase().includes(searchString.toLowerCase()),
    ),
    [files, searchString],
  );

  useEffect(() => {
    const audioFilesFilterFn = (file: S3File) => isAudioFile(file);
    const audioFiles = files.filter(audioFilesFilterFn);
    dispatch(audioPlayerActions.setPlaylist(audioFiles));
  }, [files, dispatch]);

  return (
    <TableContainer component={Paper}>
      <Fade
        in={loading}
      >
        <LinearProgress />
      </Fade>
      <Collapse
        in={Boolean(playedFile)}
        style={{ transformOrigin: '0 0 0' }}
        timeout={playedFile ? 1000 : undefined}
        mountOnEnter
        unmountOnExit
      >
        <AudioPlayer />
      </Collapse>
      <FilesTable files={filteredFiles} onChangePath={handleChangePath} />
    </TableContainer>
  );
}
