import AuthService from './Auth.service';

export default class ApiService {
  private readonly apiUrl: string = process.env.REACT_APP_API_URL as string;
  // eslint-disable-next-line class-methods-use-this
  get auth(): {} | { Authorization: '' } {
    const token = AuthService.getToken() || '';
    return { ...(token && { Authorization: `Bearer ${token}` }) };
  }

  readonly api = async (url: string, options?: RequestInit, disableHeaders?: boolean) => {
    const res = await fetch(
      `${this.apiUrl}${url}`,
      { ...options,
        credentials: 'include',
        headers: { ...this.auth, ...!disableHeaders && { 'Content-Type': 'application/json' }, ...options?.headers }, 
      },
    );
    if (res.headers.get('Content-Type')?.includes('application/json')) {
      if (res.ok) {
        return res.json();
      }
      const text = await res.text();
      throw new Error(text);
    }
    if (res.headers.get('Content-Type')?.includes('text/html')) {
      if (res.ok) {
        return res.text();
      }
      const text = await res.text();
      throw new Error(text);
    }
    console.error('Unknown content type - ', res.headers.get('Content-Type'));
    return null;
  };
}
