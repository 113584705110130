import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
    marginLeft: 'auto',
  },
  input: {
    padding: '5px 10px',
    flexBasis: '240px',
    transition: 'flex-basis 400ms ease',
    backgroundColor: theme.palette.action.selected,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused': {
      flexBasis: '80%',
    },
    borderRadius: '5px',
    color: 'white',
  },
}), { name: 'FilesSearch' });

export default useStyles;
