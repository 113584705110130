import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type SpinnerState = { requests: string[], errors: PayloadAction<unknown>[], isLoading: boolean };

const initialState: SpinnerState = {
  requests: [],
  errors: [],
  isLoading: false,
};
const PREFIX = 'spinner';

const SpinnersSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    addReqSpinner(state, action: PayloadAction<string>) {
      return {
        ...state,
        requests: state.requests.concat(action.payload),
        isLoading: true,
      };
    },
    removeReqSpinner(state, action: PayloadAction<string>) {
      const newRequests = state.requests.filter((r) => r !== action.payload);
      return {
        ...state,
        requests: newRequests,
        isLoading: newRequests.length > 0,
      };
    },
    addErrorNotification(state, action: PayloadAction<PayloadAction<unknown>>) {
      return {
        ...state,
        errors: state.errors.concat(action.payload),
      };
    },
    removeErrorNotification(state, action: PayloadAction<PayloadAction<unknown>>) {
      const updatedErrors = state.errors.filter((e) => e.type !== action.payload.type);
      return {
        ...state,
        errors: updatedErrors,
      };
    },
  },
});

export const {
  reducer: SpinnerReducer,
  actions: {
    addReqSpinner,
    removeReqSpinner,
    addErrorNotification,
    removeErrorNotification,
  },
} = SpinnersSlice;