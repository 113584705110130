import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  toolbarRoot: {
    justifyContent: 'space-between',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  navLink: {
    fontSize: '1.25rem',
    fontWeight: 500,
    textDecoration: 'none',
    color: 'white',
    '&.active': {
      textDecoration: 'underline',
    },
  },
}), { name: 'MainToolbar' });
export default useStyles;
