import qs from 'qs';
import ApiService from '../services/Api.service';

const AUDIO_FILES = ['.mp3', '.ogg', '.aac', '.flac'];
const IMG_FILES = ['.jpg', '.gif', '.jpeg', '.png'];
const VIDEO_FILES = ['.mp4'];
export const isAudioFile = (
  file: { name: string },
) => AUDIO_FILES.some((ext: string) => file.name.toLowerCase().endsWith(ext));
export const isImageFile = (
  file: { name: string },
) => IMG_FILES.some((ext: string) => file.name.toLowerCase().endsWith(ext));
export const isVideoFile = (
  file: { name: string },
) => VIDEO_FILES.some((ext: string) => file.name.toLowerCase().endsWith(ext));

export const getHrefByKey = (key: string) => `${
  process.env.REACT_APP_API_URL
}/files/download?${qs.stringify({ fileKey: key })}`;
export const getNameByKey = (key: string) => [...key.split('/')].pop();

export const downloadFile = (key: string) => {
  const apiService = new ApiService();
  const auth = apiService.auth;
  const href = getHrefByKey(key);
  const xhr = new XMLHttpRequest();
  xhr.open('GET', href, true);
  if (auth.hasOwnProperty('Authorization')) {
    xhr.setRequestHeader('Authorization', (auth as any).Authorization);
  }
  xhr.responseType = 'blob';
  xhr.onload = function (e) {
    const currentTarget = (e.currentTarget as any);
    const blob = currentTarget.response;
    // const contentDisposition = currentTarget.getResponseHeader('Content-Disposition');
    // https://stackoverflow.com/a/23054920/
    const fileName = key.split('/').at(-1) || '';
    console.log('file - ', fileName);

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };
  xhr.send();
};
