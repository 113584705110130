import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

export class FolderPermissionState {
  list: FolderPermission[] = [];
  current: FolderPermission | null = null;
}
const PREFIX = 'folderPermission';

export const getFolderPermissionsRequest = createAction(`${PREFIX}/getListRequest`);
export const getFolderPermissionsError = createAction<unknown>(`${PREFIX}/getListError`);
export const createFolderPermissionsRequest = createAction<FolderPermission>(`${PREFIX}/createRequest`);
export const createFolderPermissionsError = createAction<unknown>(`${PREFIX}/createError`);
export const updateFolderPermissionsRequest = createAction<Partial<FolderPermission>>(`${PREFIX}/updateRequest`);
export const updateFolderPermissionsError = createAction<unknown>(`${PREFIX}/updateError`);
export const deleteFolderPermissionsRequest = createAction<number>(`${PREFIX}/deleteRequest`);
export const deleteFolderPermissionsError = createAction<unknown>(`${PREFIX}/deleteError`);
export const getOneFolderPermissionsRequest = createAction<number>(`${PREFIX}/getOneRequest`);
export const getOneFolderPermissionsError = createAction<unknown>(`${PREFIX}/getOneError`);

const FolderPermissionSlice = createSlice({
  initialState: new FolderPermissionState(),
  name: PREFIX,
  reducers: {
    getListSuccess(state, action: PayloadAction<FolderPermission[]>) {
      return {
        ...state,
        list: action.payload,
      };
    },
    createSuccess(state, action: PayloadAction<FolderPermission>) {
      return {
        ...state,
        list: state.list.concat(action.payload),
      };
    },
    updateSuccess(state, action: PayloadAction<FolderPermission>) {
      return {
        ...state,
        list: state.list
          .filter((f) => f.id !== action.payload.id)
          .concat(action.payload),
      };
    },
    deleteSuccess(state, action: PayloadAction<number>) {
      return {
        ...state,
        list: state.list
          .filter((f) => f.id !== action.payload),
      };
    },
    getOneSuccess(state, action: PayloadAction<FolderPermission>) {
      return {
        ...state,
        current: action.payload,
      };
    },
    cleanCurrent(state) {
      return {
        ...state,
        current: null,
      };
    },
  },
});

export const { reducer: FolderPermissionReducer, actions: {
  getListSuccess: getFolderPermissionsSuccess,
  createSuccess: createFolderPermissionsSuccess,
  updateSuccess: updateFolderPermissionsSuccess,
  deleteSuccess: deleteFolderPermissionsSuccess,
  getOneSuccess: getOneFolderPermissionsSuccess,
  cleanCurrent: cleanCurrentFolderPermission,
} } = FolderPermissionSlice;