import { all, call, put, takeEvery } from 'redux-saga/effects';
import FolderPermissionService from '../../services/FolderPermission.service';
import {
  createFolderPermissionsError,
  createFolderPermissionsRequest,
  createFolderPermissionsSuccess,
  deleteFolderPermissionsRequest,
  deleteFolderPermissionsSuccess,
  getFolderPermissionsError,
  getFolderPermissionsRequest,
  getFolderPermissionsSuccess,
  getOneFolderPermissionsError,
  getOneFolderPermissionsRequest,
  getOneFolderPermissionsSuccess,
  updateFolderPermissionsError,
  updateFolderPermissionsRequest,
  updateFolderPermissionsSuccess,
} from './folderPermission.slice';

const service = new FolderPermissionService();

function* getListSaga() {
  try {
    const r: AsyncReturnType<typeof service.getList> = yield call([service, service.getList]);
    yield put(getFolderPermissionsSuccess(r));
  } catch (error) {
    console.log('error - ', error);

    yield put(getFolderPermissionsError(error));
  }
}

function* createSaga({ payload }: ReturnType<typeof createFolderPermissionsRequest>) {
  try {
    const r: AsyncReturnType<typeof service.create> = yield call([service, service.create], payload);
    yield put(createFolderPermissionsSuccess(r));
  } catch (error) {
    yield put(createFolderPermissionsError(error));
  }
}

function* updateSaga({ payload }: ReturnType<typeof updateFolderPermissionsRequest>) {
  try {
    const r: AsyncReturnType<typeof service.update> = yield call([service, service.update], payload);
    yield put(updateFolderPermissionsSuccess(r));
  } catch (error) {
    yield put(updateFolderPermissionsError(error));
  }
}

function* deleteSaga({ payload }: ReturnType<typeof deleteFolderPermissionsRequest>) {
  try {
    yield call([service, service.delete], payload);
    yield put(deleteFolderPermissionsSuccess(payload));
  } catch (error) {
    yield put(updateFolderPermissionsError(error));
  }
}

function* getOneSaga({ payload }: ReturnType<typeof getOneFolderPermissionsRequest>) {
  try {
    const r: AsyncReturnType<typeof service.getOne> = yield call([service, service.getOne], payload);
    yield put(getOneFolderPermissionsSuccess(r));
  } catch (error) {
    yield put(getOneFolderPermissionsError(error));
  }
}

export default function* folderPermissionSaga() {
  yield all([
    takeEvery<typeof getFolderPermissionsRequest>(getFolderPermissionsRequest, getListSaga),
    takeEvery<typeof createFolderPermissionsRequest>(createFolderPermissionsRequest, createSaga),
    takeEvery<typeof updateFolderPermissionsRequest>(updateFolderPermissionsRequest, updateSaga),
    takeEvery<typeof deleteFolderPermissionsRequest>(deleteFolderPermissionsRequest, deleteSaga),
    takeEvery<typeof getOneFolderPermissionsRequest>(getOneFolderPermissionsRequest, getOneSaga),
  ]);
}