import React, { useRef, useState, useCallback } from 'react';
import {
  Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, IconButton, Typography, ListItem,
} from '@material-ui/core';
import AccountBox from '@material-ui/icons/AccountBox';
import ExpandMore from '@material-ui/icons/ExpandMore';
// import AccountBox from '@material-ui/icons/ExpandLess';
import { useSelector, useDispatch } from 'react-redux';
// import { push } from 'connected-react-router';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import useStyles from './ProfileMenu.styles';
import { selectIsAuth, selectUser } from '../../redux/user/user.selector';
import { userLogoutRequest } from '../../redux/user/user.slice';

export default function ProfileMenu() {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(() => {
    setOpen((o) => !o);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const isAuth = useSelector(selectIsAuth);
  const user = useSelector(selectUser);
  const handleLoginClick = useCallback(() => {
    if (isAuth) {
      dispatch(userLogoutRequest());
    } else {
      // dispatch(push('/login'));
      navigate('/login');
    }
    handleClose();
  }, [dispatch, isAuth, handleClose, navigate]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <IconButton
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <AccountBox />
        <ExpandMore className={clsx(classes.expandIcon, open && classes.rotate180)} />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" className={classes.menuList}>
                  {isAuth && (
                    [
                      <ListItem key="name" divider autoFocus={false} disabled>
                        <Typography>
                          {user.firstName}
                          <span> </span>
                          {user.lastName}
                        </Typography>
                      </ListItem>,
                      <MenuItem key="profile"><Link to="/profile">Profile</Link></MenuItem>,
                    ]
                  )}
                  <MenuItem
                    onClick={handleLoginClick}
                    className={clsx(isAuth && classes.danger)}
                  >
                    {isAuth ? 'Logout' : 'Login'}

                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
